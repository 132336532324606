import gql from 'graphql-tag'

export const VENDOR_ME_QUERY = gql`
  query {
    vendorMe {
      id
      email
      avatar
      order_received
      order_pickup
    }
  }
`
export const ORDERS_QUERY = gql`
query orders($status: OrderStatus!) {
  orders(status: $status) {
    id
    name
    first_name
    last_name
    email
    status
    created_at
    customer_name
    note
    age
    received_at
  }
}
`

export const ORDERS_COUNT = gql`
query ordersCount($status: OrderStatus!) {
  ordersCount(status: $status)
}
`

export const COMPLETED_ORDERS_QUERY = gql`
query orders($status: OrderStatus!) {
  orders(status: $status) {
    id
    ref
    name
    first_name
    last_name
    email
    status
    created_at
    customer_name
    note
    age
    placement
    received_at
    pickedup_at
    pickup_first_name
    pickup_last_name
    pickup_note
    pickup_by
    account {
      id
      holder {
        id
        account_name
      }
    }
    pickup_user {
      id
      first_name
      last_name
    }
  }
}
`
