/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {VENDOR_LOGIN_MUTATION} from '../graphql/mutations'
import {VENDOR_ME_QUERY, ORDERS_COUNT} from '../graphql/queries'
import {apolloProvider, onLogout} from '../vue-apollo'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    openOrders: 0,
    readyOrders: 0,
    addedOrders: 0
  },
  getters: {
    authUser: state => state.user,
    isAuthenticated: state => !!state.user,
    openOrdersCount: state => state.openOrders,
    readyOrdersCount: state => state.readyOrders,
    addedOrdersCount: state => state.addedOrders
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    SET_USER (state, data) {
      state.user = data.user
      state.token = data.access_token
    },
    AUTH_SUCCESS (state, user) {
      state.user = user
    },
    AUTH_FAIL(state) {
      state.user = null
      state.token = null
    },
    LOGOUT(state) {
      state.user = null
      state.token = null
    },
    SET_OPEN_ORDERS_COUNT(state, count) {
      state.openOrders = count
    },
    SET_READY_ORDERS_COUNT(state, count) {
      state.readyOrders = count
    },
    SET_ADDED_ORDERS_COUNT(state, count) {
      state.addedOrders = count
    }
  },
  actions: {
    async LOGOUT({commit}) {
      await onLogout(apolloProvider.defaultClient)
      commit('LOGOUT')
      return true
    },

    async login ({ commit, dispatch }, authDetails) {
      try {
        const { data } = await apolloProvider.defaultClient.mutate({ mutation: VENDOR_LOGIN_MUTATION, variables: { ...authDetails } })
        const token = data.login.token
        commit('SET_TOKEN', token)
        localStorage.setItem('apollo-token', token)
        dispatch('setUser')  
      } catch(error) {
        commit('AUTH_FAIL')
        console.error(error)
      }      
    },
    async VENDOR_AUTH_ME ({commit}) {
      try {
        const { data } = await apolloProvider.defaultClient.query({ query: VENDOR_ME_QUERY })
        if (!data.vendorMe) {
          commit('AUTH_FAIL')  
          return false
        }
        if (data.vendorMe.__typename == 'AccountMember') {
          commit('AUTH_SUCCESS', data.vendorMe)
          return true  
        } else {
          commit('AUTH_FAIL')  
          return false
        }
      } catch(error) {
        commit('AUTH_FAIL')
        console.error(error)
        return false
      }
    },
    async OPEN_ORDERS_COUNT ({commit, dispatch}) {
      const status = 'PENDING'
      try {
        const {data} = await apolloProvider.defaultClient.query({
          query: ORDERS_COUNT, 
          variables:{status},
          fetchPolicy: 'no-cache',
        })
        console.log(data)
        commit('SET_OPEN_ORDERS_COUNT', data.ordersCount)
      } catch(error) {
        console.error(error)
      }
    },
    async READY_ORDERS_COUNT ({commit, dispatch}) {
      const status = 'READY'
      try {
        const {data} = await apolloProvider.defaultClient.query({
          query: ORDERS_COUNT, 
          variables:{status},
          fetchPolicy: 'no-cache',
        })
        console.log(data)
        commit('SET_READY_ORDERS_COUNT', data.ordersCount)
      } catch(error) {
        console.error(error)
      }
    },
    async ADDED_ORDERS_COUNT ({commit, dispatch}) {
      const status = 'ADDED'
      try {
        const {data} = await apolloProvider.defaultClient.query({
          query: ORDERS_COUNT, 
          variables:{status},
          fetchPolicy: 'no-cache',
        })
        console.log(data)
        commit('SET_ADDED_ORDERS_COUNT', data.ordersCount)
      } catch(error) {
        console.error(error)
      }
    },    
  },
  modules: {
  }
})
