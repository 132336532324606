import store from '../store'

export async function attemptAuthentication (to, from, next) {
  if (to.path !== '/login') {
    const loggedIn = await authenticate()
    console.log('loggedIn', loggedIn)
    if (loggedIn) {
      next()
    } else {
      //store.dispatch('logout')
      next('/login')
    }
  } else {
    next()
  }
} 

async function authenticate () {
  const loggedIn = await store.dispatch('VENDOR_AUTH_ME')
  return loggedIn
}
