import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'
import { apolloProvider } from './vue-apollo'
import { attemptAuthentication } from './middlewares/attemptAuthentication';
import './sass/main.scss'
// import "vue-wysiwyg/dist/vueWysiwyg.css";
//import VueFilterPluralize from '@vuejs-community/vue-filter-pluralize';
import titleMixin from './mixins/titleMixin'
import preventNavigationMixin from './mixins/preventNavigation'
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import Column2D from 'fusioncharts/fusioncharts.charts';
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import Charts from 'fusioncharts/fusioncharts.charts';
// import wysiwyg from "vue-wysiwyg";
// Vue.use(wysiwyg, {}); // config is optional. more below


Vue.config.productionTip = false


FusionCharts.options.license({
  key: "YcC1orxB1D8B1D2F3F2F2I2C2D8C6C5B-11ffI3A1A7nA3B1B3cfqH2B7B2E-13yhE1A2E1suwC7E2B4B2H4I2D3B3D7E6F4hbbD2I3IA4rveE2F2D1G-7oB1E1D3D1eevA32B2B9D4A2F2A1G4D1E1B17B2D3A3l==",
  creditLabel: false
});

Vue.use(VueFusionCharts, FusionCharts, Column2D, FusionTheme, Charts);


router.beforeEach((to, from, next) => {
  attemptAuthentication(to, from, next)
})


var filter = function(text, length, clamp){
  if (!text) return ''
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);
//Vue.use(VueFilterPluralize);

Vue.mixin(titleMixin)
Vue.mixin(preventNavigationMixin)

new Vue({
  apolloProvider: apolloProvider,
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


