const preventNavigationMixin = {
  data: function() {
    return {
      preventNav: false,
      preventNavUnless: null
    }
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.onNav)
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.onNav);
    })
  },

  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave')
    if (this.preventNav) {
      if (!window.confirm("Changes you made may not be saved. Continue ?")) {
        return;
      }
    }
    next();
  },

  methods: {
    onNav(event) {
      if (!this.preventNav) {
        return
      }
      event.preventDefault()
      event.returnValue = ""
    },
    stopNav(no) {
      this.preventNav = typeof no == 'undefined' ? true : no;
    },
    locknav() {
      console.log('locknav')
      this.preventNav = true
    },
    unlocknav() {
      console.log('unlocknav')
      this.preventNav = false
      this.preventNavUnless = null
    }
  },

  events: {
    "STOP_NAV": function(no) {
      this.stopNav(no);
    },
  }
}

export default preventNavigationMixin;