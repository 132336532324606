<template>
      <v-navigation-drawer
        v-model="drawer"
        color="white"
        :expand-on-hover="expandOnHover"
        :mini-variant="miniVariant"
        app
      >

        <v-list
          dense
          nav
          class="py-0"
        >
          <v-list-item two-line :class="miniVariant && 'px-0'">
            <v-list-item-content>
              <v-list-item-title style="color:#3B5998;text-align:center;font-size:24px;line-height:24px;">THENETWORKHUB</v-list-item-title>
              <!--<v-list-item-subtitle>Subtext</v-list-item-subtitle>-->
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item two-line :class="miniVariant && 'px-0'">
            
            <v-list-item-avatar>
              <!--<avatar :username="authUserName" color="#fff"  ></avatar>-->
              
              <v-avatar v-if="authUser" color="blue">
                    <span class="white--text">{{authUser.avatar}}</span>
              </v-avatar>

            </v-list-item-avatar>

          <v-list-item-content>
            <!--<v-list-item-title class="text-left">Eli Z.</v-list-item-title>-->
            <v-list-item-subtitle class="text-left"><a @click.prevent="logout()">Logout</a></v-list-item-subtitle>
          </v-list-item-content>

          </v-list-item>

          <v-divider></v-divider>

          <v-list-item
            class="pt-2 pb-2 mb-0 sidebar--link"
            v-for="item in items"
            :key="item.title"
            link
            :to="item.href"
          >
          
            <v-list-item-icon>
              <v-icon class="sidebar--icon">{{ item.icon }}</v-icon>
            </v-list-item-icon>
          

            <v-list-item-content>
              <v-list-item-title class="text-left">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
                  <v-badge
                    v-if="item.badge"
                    :content="item.badge_content"
                    :value="item.badge_content"
                    color="orange"
                    inline
                  >
                  </v-badge>
          </v-list-item>

          <v-divider></v-divider>

          

          <v-list-item
            class="pt-2 pb-2 mb-0 sidebar--link"
            v-for="item in manage"
            :key="item.title"
            link
            :to="item.href"
          >
            <v-list-item-icon>
              <v-icon class="sidebar--icon">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-left">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>


          </v-list-item>


        </v-list>
      </v-navigation-drawer>
</template>

<script>
//import Avatar from 'vue-avatar'
import {mapGetters} from 'vuex'
export default {
  name: 'SideBar',

  components: {
    //Avatar
  },

  data:() => ({
    expandOnHover: false,
    miniVariant: false,
    drawer: true
  }),

  computed: {
    ...mapGetters(['openOrdersCount', 'readyOrdersCount', 'authUser', 'addedOrdersCount']),
    items() {
      return [
        { title: 'Create', href: '/orders/create', icon: 'mdi-cart-arrow-down', badge: true, badge_content: this.addedOrdersCount },
        // Open Orders
        { title: 'Pending Orders', href: '/orders/open', icon: 'mdi-cart-heart', badge: true, badge_content: this.openOrdersCount },
        // Ready for Pickup
        { title: 'Pending Pickups', href: '/orders/ready', icon: 'mdi-cart-heart', badge: true, badge_content: this.readyOrdersCount },
        { title: 'Completed Orders', href: '/orders/completed', icon: 'mdi-cart-check' },
      ]
    },
    manage() {
      return [
        { title: 'Email Settings', icon: 'mdi-email-outline', href: '/settings/emails'},
        { title: 'Profile', icon: 'mdi-clipboard-account-outline', href: '/profile'},
        { title: 'Reporting', icon: 'mdi-chart-areaspline', href: '/reports'},
      ]
    }
  },

  mounted() {
    this.$store.dispatch('ADDED_ORDERS_COUNT', this.$apolloProvider)
    this.$store.dispatch('OPEN_ORDERS_COUNT', this.$apolloProvider)
    this.$store.dispatch('READY_ORDERS_COUNT', this.$apolloProvider)
    const self = this
    this.$root.$on('toggleSidebar', function() {
      self.drawer = !self.drawer
    })
  },

  methods: {
    async logout() {
      await this.$store.dispatch('LOGOUT');
      this.$router.push('/login')
    }
  }
}
</script>