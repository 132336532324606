import Vue from 'vue'
import VueRouter from 'vue-router'
import SideBar from '../components/Sidebar'

Vue.use(VueRouter)

const routes = [
  { path: '/profile', name: 'Profile',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "orders" */ '../views/Profile.vue')
    }
  },
  { path: '/reports', name: 'Reporting',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "orders" */ '../views/Reports.vue')
    }
  },
  { path:'/settings', redirect: '/settings/emails' },
  { path:'/settings/emails', name:'EmailSettings',  
    components: {
      sidebar: SideBar,
      default: () => import('../views/EmailSettings.vue')
    }
  },
  {
    path: '/orders/create',
    alias: '/',
    name: 'CreateOrder',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "orders" */ '../views/CreateOrder.vue')
    }
  },
  {
    path: '/orders/open',
    alias: '/',
    name: 'OpenOrder',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "orders" */ '../views/OpenOrders.vue')
    }
  },
  {
    path: '/orders/ready',
    alias: '/',
    name: 'ReadyOrder',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "orders" */ '../views/ReadyOrders.vue')
    }
  },
  {
    path: '/orders/completed',
    alias: '/',
    name: 'CompletedOrder',
    components: {
      sidebar: SideBar,
      default: () => import(/* webpackChunkName: "orders" */ '../views/CompletedOrders.vue')
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
