import gql from 'graphql-tag'
export const VENDOR_LOGIN_MUTATION = gql`
mutation vendorLogin ($input: LoginInput){
  vendorLogin(input: $input){
    error
    access_token
    user {
      id
      email
    }
  }
}
`

export const CREATE_ORDER_MUTATION = gql`
mutation createOrder (
  $name: String!
  $first_name: String!
  $last_name: String
  $email: String
  $note: String
) {
  createOrder(input: {
    name: $name
    first_name: $first_name
    last_name: $last_name
    email: $email
    note: $note
  }) {
    id
    name
    first_name
    last_name
    email
    status
    note
    customer_name
    created_at
  }
}
`

export const UPDATE_ORDER_EMAIL_TEMPLATES_MUTATION = gql`
mutation updateOrderEmailTemplates(
  $settings: OrderEmailSettingsInput
  $templates: [OrderEmailTemplateInput]
) {
  updateOrderEmailTemplates(input: {
    settings: $settings
    templates: $templates
  })
}
`

export const DELETE_ORDER_MUTATION = gql`
mutation deleteOrder($id: ID!) {
  deleteOrder(id: $id) {
    id
  }
}
`

export const CANCEL_ORDER_MUTATION = gql`
mutation cancelOrder($id: ID) {
  cancelOrder(id: $id) {
    id
  }
}
`

export const DELETE_ORDERS_MUTATION = gql`
mutation deleteOrders($ids: [ID!]!) {
  deleteOrders(ids: $ids) {
    id
  }
}
`

export const PROCESS_ADDED_ORDERS_MUTATION = gql`
mutation processAddedOrders($ids: [ID]) {
  processAddedOrders(ids: $ids) {
    id
  }
}
`

export const UPDATE_ORDER = gql`
mutation updateOrder(
  $id: ID!
  $first_name: String!
  $last_name: String
  $email: String
  $note: String
) {
  updateOrder(
    id: $id
    first_name: $first_name
    last_name: $last_name
    email: $email
    note: $note
  ) {
    id
    first_name
    last_name
    email
    note
  }
}
`

export const UPDATE_ORDER_EMAIL_SETTING_MUTATION = gql`
mutation updateOrderEmailSetting (
  $id: ID!
  $sender_name: String
  $sender_email: String
  $enable_order_pickup_email: Int
  $subject: String
  $body: String
) {
  updateOrderEmailSetting (
    id: $id
    sender_name: $sender_name
    sender_email: $sender_email
    enable_order_pickup_email: $enable_order_pickup_email
    subject: $subject
    body: $body
  ) {
    id
  }
}
`

export const UPDATE_USER_PASSWORD = gql`
mutation updateUserPassword (
  $password: String
) {
  updateUserPassword (
    password: $password
  )
}
`
export const UPDATE_VENDOR_PROFILE = gql`
mutation updateVendorProfile (
  $input: VendorProfileInput
) {
  updateVendorProfile (
    input: $input
  )
}
`

export const SEND_REMINDERS_MUTATION = gql`
mutation sendReminders (
  $ids: [ID]
) {
  sendReminders(
    ids: $ids
  )
}
`